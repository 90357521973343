const HomeStory = ({ heading, paragraphs, services, link }) => (
  <section className="bg-near-white pv5">
    <div className="container sm:pv3 md:pv4 lg:pv5">
      <h2 className="section-heading mb4 sm:pb3" style={{ maxWidth: `14ch` }}>
        <span dangerouslySetInnerHTML={{ __html: heading.black }} />
        <span className="db yellow">{heading.yellow}</span>
      </h2>

      <p className="lh-copy">{paragraphs[0]}</p>

      <ul className="heading mt4 lh-title sm:f4">
        {services.map((service, i) => (
          <li key={i}>{service}</li>
        ))}
      </ul>

      <p className="mt4 lh-copy">{paragraphs[1]}</p>

      <Link to={link.href} className="link heading mt4 sm:pt2 f4 yellow">
        {link.text}
      </Link>
    </div>
  </section>
)

/*
 *
 * Imports & Exports
 * 
 */

import React from 'react'
import { Link } from 'gatsby'

export default HomeStory
