const HomeVideos = ({ heading, paragraph, videos }) => (
  <section className="container pv5 sm:mv3 md:mv4 lg:mv5">
    <h2 className="section-heading mb4 sm:pb3">
      <span>{heading.black}</span>
      {` `}
      <span
        className="yellow"
        dangerouslySetInnerHTML={{ __html: heading.yellow }}
      />
    </h2>

    <p className="measure lh-copy">{paragraph}</p>

    <Gallery videos={videos} />
  </section>
)

/*
 *
 * Gallery
 *
 */

const Gallery = ({ videos }) => {
  let limit = 3

  // Wait for the document to exist
  if (typeof window !== `undefined`) {
    // Create a test element to check for grid support
    const testEl = document.createElement(`div`)
    testEl.style.display = `grid`

    // If grid is supported, show more items initially on large screens
    if (testEl.style.display === `grid`) {
      if (window.matchMedia(`(min-width: 48em)`).matches) {
        limit = 4
      }
    }
  }

  return (
    <Limit items={videos} limit={limit} increment={limit}>
      {(visibleItems, limited, handleLoadMore) => (
        <>
          <VideoGallery items={videos} visibleItems={visibleItems} />
          {limited && <ViewMore handleLoadMore={handleLoadMore} />}
        </>
      )}
    </Limit>
  )
}

/*
 *
 * Image Gallery
 *
 */

const VideoGallery = ({ items, visibleItems }) => (
  <VideoGalleryAndLightbox
    galleryItems={visibleItems}
    lightboxItems={items}
    renderGallery={(galleryImages, handleImageClick) => (
      <Thumbnails items={galleryImages} handleImageClick={handleImageClick} />
    )}
  />
)

/*
 *
 * Items
 *
 */

const Thumbnails = ({ items, handleImageClick }) => (
  <ul className="videos-grid nt3">
    <TransitionGroup component={null}>
      {items.map((item, i) => (
        <Mount
          key={i} // MUST be unique
          animateSpace={false}
          animateExit={false}
          enterTimeout={700}
          exitTimeout={0}
        >
          <Thumbnail
            item={item.node}
            lightboxIndex={i}
            handleImageClick={handleImageClick}
          />
        </Mount>
      ))}
    </TransitionGroup>
  </ul>
)

/*
 *
 * Thumbnail
 *
 */

const Thumbnail = ({ item, lightboxIndex, handleImageClick, className = `` }) => (
  <li className={`mt5 ${className}`}>
    <div className="group relative">
      <Img
        fluid={item.image.file.childImageSharp.fluid}
        alt={item.image.alt}
        objPosition={item.image.objPosition}
        className="aspect-ratio aspect-ratio--8x5"
      />

      {/* Play button */}
      <div className="flex items-center absolute bottom-0 left-0 pl3 pb3">
        <div
          className="flex justify-center items-center br-100 bg-white"
          style={{ paddingLeft: `.3rem`, width: `2.5rem`, height: `2.5rem` }}
        >
          <div
            style={{
              borderTop: `11px solid transparent`,
              borderBottom: `11px solid transparent`,
              borderLeft: `14px solid black`
            }}
          />
        </div>

        <p className="pl2 fw7 white">{item.length}</p>
      </div>

      {/* Overlay + Lightbox trigger */}
      <button
        onClick={handleImageClick}
        value={lightboxIndex}
        className="flex justify-center items-center absolute fill bg-transparent w-100 group-hover:bg-black-50 f4 ttu transparent group-hover:white animate"
      >
        Watch video
      </button>
    </div>

    {/* Info */}
    <h3 className="heading mt3 lh-title">
      <span className="yellow">{item.title.yellow}</span>
      {` `}
      <span>
        {item.title.black}
        &nbsp;
      </span>
    </h3>
  </li>
)

/*
 *
 * See More Button
 *
 */

const ViewMore = ({ handleLoadMore }) => (
  <button onClick={handleLoadMore} className="link heading mt4 pt3 f4 yellow">
    View more
  </button>
)

/*
 *
 * Import & Exports
 *
 */

import React from 'react'
import TransitionGroup from 'react-transition-group/TransitionGroup'

import VideoGalleryAndLightbox from '../components/VideoGalleryAndLightbox'
import Img from '../components/Img'
import Limit from '../components/Limit'
import Mount from '../components/Mount'

export default HomeVideos
