const HomeClients = ({
  heading,
  paragraph,
  residentialClients,
  industrialClients
}) => (
  <section className="bg-near-white pv5">
    <div className="sm:pv3 md:pb4 lg:pt4 lg:pb5">
      <div className="container">
        <h2 className="section-heading mb4 sm:pb3">
          {heading.black}
          <span className="db yellow">{heading.yellow}</span>
        </h2>

        <p className="sm:pb3 measure lh-copy">{paragraph}</p>
      </div>

      <Accordion
        renderHeader={expanded => <ResidentialHeader expanded={expanded} />}
        renderDetails={() => <ResidentialDetails clients={residentialClients} />}
      />

      <Accordion
        renderHeader={expanded => <IndustrialHeader expanded={expanded} />}
        renderDetails={() => <IndustrialDetails clients={industrialClients} />}
      />
    </div>
  </section>
)

/* 
 *
 * Residential Header
 * 
 */

const ResidentialHeader = ({ expanded }) => (
  <div className="container group flex justify-between items-start pv2">
    <h2 className="section-subheading ttc">Residential Clients &amp; Partners</h2>

    <Icon expanded={expanded} />
  </div>
)

const Icon = ({ expanded }) => (
  <span
    aria-hidden="true"
    className={`heading dn md:db animate${!expanded && ` o-0 group-hover:o-100`}`}
    style={{ fontSize: `2.5rem`, lineHeight: 0.7 }}
  >
    {expanded ? `-` : `+`}
  </span>
)

/*
 *
 * Residential Details
 *
 */

const ResidentialDetails = ({ clients }) => {
  let limit = 12

  // Wait for the document to exist
  if (typeof window !== `undefined`) {
    // At medium screens (where multi-column kicks in), show all items
    if (window.matchMedia(`(min-width: 48em)`).matches) {
      limit = 100
    }
  }

  return (
    <Limit items={clients} limit={limit} increment={100}>
      {(visibleItems, limited, handleLoadMore) => (
        <div className="container mt4">
          <ResidentialClientList visibleItems={visibleItems} />
          {limited && <ViewMore handleLoadMore={handleLoadMore} />}
        </div>
      )}
    </Limit>
  )
}

const ResidentialClientList = ({ visibleItems }) => (
  <ul className="clients-columns lh-copy f4">
    {visibleItems.map(client => (
      <li key={client.node.name}>{client.node.name}</li>
    ))}
  </ul>
)

/* 
 *
 * Industrial Header
 * 
 */

const IndustrialHeader = ({ expanded }) => (
  <div className="container group flex justify-between items-start pv2">
    <h2 className="section-subheading ttc">Industrial Clients &amp; Partners</h2>

    <Icon expanded={expanded} />
  </div>
)

/*
 *
 * Industrial Details
 *
 */

const IndustrialDetails = ({ clients }) => {
  let limit = 12

  // Wait for the document to exist
  if (typeof window !== `undefined`) {
    // At medium screens (where multi-column kicks in), show all items
    if (window.matchMedia(`(min-width: 48em)`).matches) {
      limit = 100
    }
  }

  return (
    <Limit items={clients} limit={limit} increment={100}>
      {(visibleItems, limited, handleLoadMore) => (
        <div className="container mt4">
          <IndustrialClientList visibleItems={visibleItems} />
          {limited && <ViewMore handleLoadMore={handleLoadMore} />}
        </div>
      )}
    </Limit>
  )
}

const IndustrialClientList = ({ visibleItems }) => (
  <ul className="clients-columns lh-copy f4">
    {visibleItems.map(client => (
      <li key={client.node.name}>{client.node.name}</li>
    ))}
  </ul>
)

/*
 *
 * View More Button
 *
 */

const ViewMore = ({ handleLoadMore }) => (
  <button onClick={handleLoadMore} className="link heading mt4 f4 yellow">
    View all
  </button>
)

/*
 *
 * Imports & Exports
 * 
 */

import React from 'react'

import Accordion from '../components/Accordion'
import Limit from '../components/Limit'

export default HomeClients
