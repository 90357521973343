const IndexPage = ({ data }) => {
  const hero = data.homeYaml.hero

  const contact = data.contactYaml

  const story = data.homeYaml.story

  const services = data.homeYaml.services
  const serviceList = data.allServiceListYaml.edges

  const testimonials = data.homeYaml.testimonials
  const testimonialsList = data.allTestimonialsYaml.edges

  const clients = data.homeYaml.clients
  const clientsResidential = data.allClientsResidentialYaml.edges
  const clientsIndustrial = data.allClientsIndustrialYaml.edges

  const videos = data.homeYaml.videos
  const videoList = data.allVideosYaml.edges

  const charities = data.homeYaml.charities
  const charitiesList = data.allCharitiesYaml.edges

  // Remove Asphalt from the list of services shown on the Contact form
  const contactServiceList = serviceList.filter(
    service => service.node.heading !== 'Asphalt Maintenance'
  )

  // Remove Asphalt and Concrete from the list of services shown on the Home page
  const homeServiceList = serviceList.filter(
    service =>
      service.node.heading !== 'Asphalt Maintenance' &&
      service.node.heading !== 'Concrete'
  )

  // Extract the service headings for the HomeStory section
  const homeStoryServices = contactServiceList.map(service => service.node.heading)

  return (
    <Base>
      <main id="main-content" tabIndex="-1" className="mukta dark-gray">
        <Hero
          title={hero.title}
          tagline={hero.tagline}
          link={hero.link}
          image={hero.image}
          video={hero.video}
        />

        <Contact
          stamp={contact.stamp}
          heading={contact.heading}
          invitation={contact.invitation}
          services={contactServiceList}
          successMessage={contact.successMessage}
        />

        <Story
          heading={story.heading}
          paragraphs={story.paragraphs}
          services={homeStoryServices}
          link={story.link}
        />

        <Services
          heading={services.heading}
          paragraph={services.paragraph}
          link={services.link}
          services={homeServiceList}
        />

        <Testimonials
          heading={testimonials.heading}
          paragraph={testimonials.paragraph}
          testimonials={testimonialsList}
        />

        <Clients
          heading={clients.heading}
          paragraph={clients.paragraph}
          residentialClients={clientsResidential}
          industrialClients={clientsIndustrial}
        />

        <Videos
          heading={videos.heading}
          paragraph={videos.paragraph}
          videos={videoList}
        />

        <Community
          heading={charities.heading}
          paragraph={charities.paragraph}
          charities={charitiesList}
        />
      </main>
    </Base>
  )
}

/*
 *
 * Queries
 * 
 */

export const query = graphql`
  query {
    homeYaml {
      hero {
        title {
          white
          white2
          yellow
        }
        tagline
        link {
          href
          text
        }
        image {
          file {
            childImageSharp {
              fluid(maxWidth: 6000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          alt
          objPosition
        }
        video
      }
      story {
        heading {
          black
          yellow
        }
        link {
          href
          text
        }
        paragraphs
      }
      services {
        heading {
          black
          yellow
        }
        paragraph
        link {
          href
          text
        }
      }
      testimonials {
        heading
        paragraph
      }
      clients {
        heading {
          black
          yellow
        }
        paragraph
      }
      videos {
        heading {
          black
          yellow
        }
        paragraph
      }
      charities {
        heading {
          black
          yellow
        }
        paragraph
      }
    }
    allServiceListYaml {
      edges {
        node {
          heading
          paragraph
          image {
            file {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            alt
            objPosition
          }
        }
      }
    }
    allTestimonialsYaml {
      edges {
        node {
          heading
          quotation
          source
        }
      }
    }
    allClientsResidentialYaml {
      edges {
        node {
          name
        }
      }
    }
    allClientsIndustrialYaml {
      edges {
        node {
          name
        }
      }
    }
    allCharitiesYaml {
      edges {
        node {
          name
        }
      }
    }
    allVideosYaml {
      edges {
        node {
          href
          title {
            yellow
            black
          }
          length
          image {
            file {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            alt
            objPosition
          }
        }
      }
    }
    contactYaml {
      stamp {
        largeText
        smallText
      }
      heading
      invitation
      successMessage
    }
  }
`

/*
 *
 * Imports & Exports
 * 
 */

import React from 'react'
import { graphql } from 'gatsby'

import Base from '../components/Base'
import Hero from '../components/Hero'
import Story from '../sections/HomeStory'
import Services from '../sections/HomeServices'
import Testimonials from '../sections/HomeTestimonials'
import Clients from '../sections/HomeClients'
import Videos from '../sections/HomeVideos'
import Community from '../sections/HomeCommunity'
import Contact from '../sections/Contact'

export default IndexPage
